.card-style {
    max-width: 280px;
    height: 80px;
    margin-top: 15px;
    box-sizing: border-box;
    overflow: hidden;
}

.cardImg {
    /* border-radius: 50%; */
    /* padding: 0px 0px 5px 2px; */
    min-height: 80px;
    box-sizing: border-box;
    object-fit: cover;
    width: 60px;
}

.latest-title {
    color: #333;
    opacity: .8;
    font-weight: 500;
    font-family: 'Varela Round', sans-serif;
}

@media screen and (max-width: 770px) {
    .card-style {
        display: block;
        height: auto;
        max-width: 100%;
        /* margin: 10px 0px; */
    }

    .cardImg {
        max-height: 200px;
        display: block;
        width: 100%;
    }
}