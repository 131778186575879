.login {
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* Alternatively */
    /* background-image: linear-gradient(
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0.5)
        ), 
        url("https://fakestoreapi.com/img/71HblAHs5xL._AC_UY879_-2.jpg"); */
    background-size: cover;
    font-family: 'Lora', serif;
    font-size: 1.5em;
    color: #fff;
    min-width: 100%;
}

.loginTitle {
    font-size: 40px;
}

.loginForm {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.loginForm > label {
    margin: 10px 0;
}

.loginInput {
    padding: 5px;
    background: #fff;
    border: none;
    outline: none;
    border-radius: 2px;
    font-size: 1.1rem;
}

.googleBtnLogin {
    margin-top: 20px;
    /* padding: 10px; */
    /* height: 20px; */
}

.loginButton {
    margin-top: 20px;
    cursor: pointer;
    background: lightcoral;
    border: none;
    color: #fff;
    border-radius: 10px;
    padding: 5px;
    transition: all .2s;

}

.loginButton:hover {
    opacity: .8;
}

.loginRegisterButton {
    position: absolute;
    top: 60px;
    right: 20px;
    background: teal;
    cursor: pointer;
    border: none;
    padding: 10px;
    color: #fff;
    border-radius: 10px;
}

@media screen and (max-width: 360px) {
    .login {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 300px) {
    .login {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 260px) {
    .login {
        font-size: 1rem;
    }
}

@media screen and (max-width: 380px) {
    .loginRegisterButton {
        display: none;
    }
}

