.write {
    padding-top: 50px;
    background: lightblue;
    background-image: linear-gradient( rgba(0, 0, 0, .1),rgba(0, 0, 0, .1)),url('../../components/asset/pexelbg2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    color: rgb(121, 118, 118);
}

.write > p {
    color: #fff;
}

.writeImg {
    margin-left: 150px;
    width: 70vw;
    height: 250px;
    border-radius: 10px;
    object-fit: cover;
    color: #fff;
}

.writeForm {
    position: relative;
}

.writeFormGroup {
    margin-left: 150px;
    display: flex;
    align-items: center;

}

.writeIcon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    /* color: rgb(121, 118, 118); */
    color: #333;
    background: #fff;
    cursor: pointer;
}

.writeInput {
    font-size: 30px;
    border: none;
    padding: 20px;
    width: 70vw;
    background: none;
    resize: none;
    color: #fff;
}

.writeInput:focus {
    outline: none;
}

.writeText {
    font-size: 25px;
    height: 100vh;
    margin-top: 50px;
}

.writeSubmit {
    position: absolute;
    top: 20px;
    right: 50px;
    color: #fff;
    background: teal;
    padding: 10px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
}

.writeCat {
    font-size: 25px;
    border: none;
    margin-bottom: 10px;
    color: rgb(121, 118, 118);
    letter-spacing: 2px;
    margin-left: 15px;
    background: none;
}

.writeCat:focus {
    outline: none;
}


@media screen and (max-width: 700px) {
    .writeImg, .writeFormGroup  {
       margin: 10px auto;
    }
    .writeSubmit {
        right: 30px;
    }
}